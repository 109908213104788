import React from 'react';
import { Link } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import sc from 'config/theme';
import {
  InputRange,
  Input,
  Dropdown,
  Header,
  Button,
  InputGroup,
} from 'components/ui';
import SEO from '../components/seo';

const Row = styled.div`
  background: ${sc.colors.main};
  padding: 5px;
  margin: 15px 0;
  box-sizing: border-box;
`;

const friendOptions = [
  {
    key: 'Jenny Hess',
    text: 'Jenny Hess',
    value: 'Jenny Hess',
  },
  {
    key: 'Elliot Fu',
    text: 'Elliot Fu',
    value: 'Elliot Fu',
  },
  {
    key: 'Stevie Feliciano',
    text: 'Stevie Feliciano',
    value: 'Stevie Feliciano',
  },
  {
    key: 'Christian',
    text: 'Christian',
    value: 'Christian',
  },
  {
    key: 'Matt',
    text: 'Matt',
    value: 'Matt',
  },
  {
    key: 'Justen Kitsune',
    text: 'Justen Kitsune',
    value: 'Justen Kitsune',
  },
];

const Description = ({ text }) => (
  <Grid.Column
    style={{
      background: `rgba(${sc.colors.main_rgb},0.9)`,
      borderBottom: '1px solid rgba(0,0,0,0.2)',
      paddingBottom: '2.5rem',
      paddingTop: '1rem',
    }}
  >
    <Header as="h2">{text}</Header>
  </Grid.Column>
);

const Content = ({ children }) => (
  <Grid.Column
    style={{
      background: sc.colors.main,
      borderBottom: '1px solid rgba(0,0,0,0.2)',
      paddingBottom: '2.5rem',
      paddingTop: '1rem',
      paddingRight: '2rem',
    }}
  >
    {children}
  </Grid.Column>
);

const StyleGuide = () => (
  <React.Fragment>
    <SEO title="Style Guide" />
    <Grid columns={2} divided style={{ paddingTop: 14 }}>
      <Grid.Row style={{ padding: 0 }}>
        <Description text="Inputs and Text Areas" />
        <Content>
          <Row>
            <Input placeholder="Normal" />
          </Row>
          <Row>
            <Input fluid placeholder="Fluid" />
          </Row>
          <Row>
            <InputGroup>
              <Input placeholder="Flex" />
              <Input placeholder="Flex" />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Input fluid placeholder="Flex and large" />
              <Input fluid placeholder="Flex and large" />
            </InputGroup>
          </Row>
        </Content>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Description text="Range" />
        <Content>
          <Row>
            <InputRange />
          </Row>
        </Content>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Description text="Dropdowns" />
        <Content>
          <Row>
            <Dropdown placeholder="Normal" selection options={friendOptions} />
          </Row>
          <Row>
            <Dropdown
              placeholder="Fluid"
              fluid
              selection
              options={friendOptions}
            />
          </Row>
          <Row>
            <InputGroup>
              <Dropdown
                placeholder="Normal"
                selection
                options={friendOptions}
              />
              <Dropdown
                placeholder="Normal"
                selection
                options={friendOptions}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Dropdown
                placeholder="Fluid"
                fluid
                selection
                options={friendOptions}
              />
              <Dropdown
                placeholder="Fluid"
                fluid
                selection
                options={friendOptions}
              />
            </InputGroup>
          </Row>
        </Content>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Description text="Buttons" />
        <Content>
          <Row>
            <Button>Normal</Button>
          </Row>
          <Row>
            <Button fluid>Normal & Fluid</Button>
          </Row>
          <Row>
            <Button alt>Alternative</Button>
          </Row>
          <Row>
            <InputGroup>
              <Button alt>Alternative</Button>
              <Button>Normal</Button>
              <Button alt>Alternative</Button>
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <Button alt fluid>
                Alternative Fluid
              </Button>
              <Button fluid>Normal Fluid</Button>
              <Button alt fluid>
                Alternative Fluid
              </Button>
            </InputGroup>
          </Row>
          <Row>
            <Button alt fluid>
              Alternative & Fluid
            </Button>
          </Row>

          <Row style={{ background: 'white' }}>
            <Button main>Main</Button>
          </Row>
          <Row style={{ background: 'white' }}>
            <Button fluid main>
              Main & Fluid
            </Button>
          </Row>
        </Content>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          <Header color="main" as="h2">
            Progress
          </Header>
        </Grid.Column>
        <Grid.Column />
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          <Header color="main" as="h2">
            Calendar
          </Header>
        </Grid.Column>
        <Grid.Column />
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          <Header color="main" as="h2">
            Pills
          </Header>
        </Grid.Column>
        <Grid.Column />
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          <Header color="main" as="h2">
            FAQ
          </Header>
        </Grid.Column>
        <Grid.Column />
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column>
          <Header color="main" as="h2">
            Cards
          </Header>
        </Grid.Column>
        <Grid.Column />
      </Grid.Row>
    </Grid>
    <Link to="/">Go back to the homepage</Link>
  </React.Fragment>
);

export default StyleGuide;
